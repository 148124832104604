// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyBiF2skor435rlJkfDLtAk0XAagxrDIA-U';
GoogleMapsLoader.VERSION = 'quarterly';

if ($('#gmap-simple').length || $('#gmap-cluster').length) {
    GoogleMapsLoader.load(functions);
}

function functions(google) {

  gmap_simple = function() {

      var element = document.getElementById("gmap-simple");
      var map;
      var mapLat = Number(element.dataset.mapLat);
      var mapLng = Number(element.dataset.mapLng);
      var mapZoom = Number(element.dataset.mapZoom);
      var myLatLng = {
          lat: mapLat,
          lng: mapLng
      };

      map = new google.maps.Map(element, {
          mapTypeId: 'roadmap',
          scrollwheel: false,
          panControl: false,
          zoomControl: true,
          zoom: mapZoom,
          center: myLatLng,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false
      });

      var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
      });

      console.log(myLatLng, map)
  }

  gmap_cluster = function() {

    var markerCluster = require('js-marker-clusterer');

    var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
      zoom: 2,
      center: {
        lat: 31.857457,
        lng: -40.820324
      }
    });

    var infoWin = new google.maps.InfoWindow();
    var markers = locations.map(function(location, i) {
      var marker = new google.maps.Marker({
        position: location
      });
      google.maps.event.addListener(marker, 'click', function(evt) {
        infoWin.setContent(location.info);
        infoWin.open(map, marker);
      });
      return marker;
    });

    var markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  }

  if ($('#gmap-simple').length) {
    gmap_simple();
  }
  if ($('#gmap-cluster').length) {
    gmap_cluster();
  }

}
