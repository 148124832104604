// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

$('#slick-home-intro').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-home-intro-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite carousel-prev"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite carousel-next"></span></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {
        autoplay: false,
      }
    },
  ]
});

setTimeout(function() {
  $('#slick-home-intro .slick-active').addClass('animation');
}, 250);

$('#slick-home-intro').on('afterChange', function(event, slick, currentSlide) {
  $('#slick-home-intro .slick-active').addClass('animation');
});

$('#slick-home-intro').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick-home-intro .slick-active').removeClass('animation');
});

function slickHomeHeight() {
  function action(){
    var viewport = $(window).height();
    var header = $('#header').innerHeight();
    $('#slick-home-intro .item').height(viewport - header);
  }
  action();
  $(window).on('resize', function(){
    action();
  });
}

//slickHomeHeight();


// Example
$('#slick-home-middle').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  arrows: false,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});


$('#slick-blog').slick({
  slidesToShow: 4,
  slidesToScroll: 2,
  dots: true,
  fade: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },
  ]
});
